@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Barlow";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.chicle-regular {
  font-family: "Chicle", serif;
  font-weight: 400;
  font-style: normal;
}

.mainbg {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  min-height: 100vh;
  padding: 0px 0px 0px;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.mainbg2 {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  min-height: 100vh;
  padding: 0px 0px 0px;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.landingbg {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  min-height: 100vh;
  padding: 0px 0px 0px;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.roadmapbg {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  min-height: 100vh;
  padding: 0px 0px 0px;
  background-size: cover;
  background-repeat: no-repeat;
}

.collectionsbg {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  min-height: 100vh;
  padding: 0px 0px 0px;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

html,
body {
  margin: 0;
  height: 100%;
  background-color: #f4f4f4;
}

.ReactModalPortal {
  position: absolute;
  z-index: 100;
}

.ticket ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.ticket ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(200, 200, 200, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(200, 200, 200, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

canvas {
  width: 100%;
  position: fixed;
  display: block;
  left: 0;
  bottom: 0;
  height: 100%;
  z-index: 0;
}

#root {
  min-height: 100%;
  font-family: "Barlow";
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.polygonpath {
  stroke-dasharray: 2500;
  stroke-dashoffset: 2500;
  animation: dash 2s linear alternate infinite;
}

@keyframes dash {
  from {
    stroke-dashoffset: 2000;
  }
  to {
    stroke-dashoffset: 0;
  }
}
